import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
	{
		path: "/index",
		meta: {
			title:'九秀直播 - 直播大厅 - 美女视频 - 视频聊天 - 视频交友 - 美女秀场'
		},
		component: () => import("@/views/index"),
	},
	{
		path: "/room",
		component: () => import("@/views/room"),
	},
	{
		path: "/download",
		component: () => import("@/views/download"),
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('@/components/login/index'),
    },
	{
		path: '/anchorDetail',
		name: 'anchorDetail',
		component: () => import('@/components/anchorDetail/index'),
	},
	{
		path: '/follow',
		name: 'follow',
		component: () => import('@/views/follow'),
	},
	{
		path: '/my',
		name: 'my',
		component: () => import('@/views/my'),
	},
];

const router = new VueRouter({
	base: 'h5live',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
        document.title = to.meta.title;
    }
	next();
});

export default router;