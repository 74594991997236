<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {}
    }
</script>

<style lang="scss">
    *{  margin: 0px; padding: 0px; font-style: normal; list-style: none; line-height: 100%;}
    #app {
        height: 100%;
    }
</style>
