(function(window){
    var winobj = window.winobj || {};

    //财富 & 主播等级数值
    winobj.arrLiveLevel = new Array();
    winobj.arrLiveLevel[1] = {};
    winobj.arrLiveLevel[1].credit = 0;
    winobj.arrLiveLevel[2] = {};
    winobj.arrLiveLevel[2].credit = 50000;
    winobj.arrLiveLevel[3] = {};
    winobj.arrLiveLevel[3].credit = 200000;
    winobj.arrLiveLevel[4] = {};
    winobj.arrLiveLevel[4].credit = 500000;
    winobj.arrLiveLevel[5] = {};
    winobj.arrLiveLevel[5].credit = 1000000;
    winobj.arrLiveLevel[6] = {};
    winobj.arrLiveLevel[6].credit = 2000000;
    winobj.arrLiveLevel[7] = {};
    winobj.arrLiveLevel[7].credit = 4000000;
    winobj.arrLiveLevel[8] = {};
    winobj.arrLiveLevel[8].credit = 7500000;
    winobj.arrLiveLevel[9] = {};
    winobj.arrLiveLevel[9].credit = 13000000;
    winobj.arrLiveLevel[10] = {};
    winobj.arrLiveLevel[10].credit = 21000000;

    winobj.arrLiveLevel[11] = {};
    winobj.arrLiveLevel[11].credit = 33000000;
    winobj.arrLiveLevel[12] = {};
    winobj.arrLiveLevel[12].credit = 48000000;
    winobj.arrLiveLevel[13] = {};
    winobj.arrLiveLevel[13].credit = 68000000;
    winobj.arrLiveLevel[14] = {};
    winobj.arrLiveLevel[14].credit = 98000000;
    winobj.arrLiveLevel[15] = {};
    winobj.arrLiveLevel[15].credit = 148000000;
    winobj.arrLiveLevel[16] = {};
    winobj.arrLiveLevel[16].credit = 228000000;
    winobj.arrLiveLevel[17] = {};
    winobj.arrLiveLevel[17].credit = 348000000;
    winobj.arrLiveLevel[18] = {};
    winobj.arrLiveLevel[18].credit = 528000000;
    winobj.arrLiveLevel[19] = {};
    winobj.arrLiveLevel[19].credit = 778000000;
    winobj.arrLiveLevel[20] = {};
    winobj.arrLiveLevel[20].credit = 1108000000;
    winobj.arrLiveLevel[21] = {};
    winobj.arrLiveLevel[21].credit = 1518000000;
    winobj.arrLiveLevel[22] = {};
    winobj.arrLiveLevel[22].credit = 2018000000;
    winobj.arrLiveLevel[23] = {};
    winobj.arrLiveLevel[23].credit = 2618000000;
    winobj.arrLiveLevel[24] = {};
    winobj.arrLiveLevel[24].credit = 3318000000;
    winobj.arrLiveLevel[25] = {};
    winobj.arrLiveLevel[25].credit = 4118000000;
    winobj.arrLiveLevel[26] = {};
    winobj.arrLiveLevel[26].credit = 5018000000;
    winobj.arrLiveLevel[27] = {};
    winobj.arrLiveLevel[27].credit = 6018000000;
    winobj.arrLiveLevel[28] = {};
    winobj.arrLiveLevel[28].credit = 7118000000;
    winobj.arrLiveLevel[29] = {};
    winobj.arrLiveLevel[29].credit = 8368000000;
    winobj.arrLiveLevel[30] = {};
    winobj.arrLiveLevel[30].credit = 9868000000;
    winobj.arrLiveLevel[31] = {};
    winobj.arrLiveLevel[31].credit = 10868000000;
    winobj.arrLiveLevel[32] = {};
    winobj.arrLiveLevel[32].credit = 12868000000;
    winobj.arrLiveLevel[33] = {};
    winobj.arrLiveLevel[33].credit = 15868000000;

    winobj.arrLiveLevel[34] = {};
    winobj.arrLiveLevel[34].credit = 19368000000;
    winobj.arrLiveLevel[35] = {};
    winobj.arrLiveLevel[35].credit = 23368000000;
    winobj.arrLiveLevel[36] = {};
    winobj.arrLiveLevel[36].credit = 28368000000;
    winobj.arrLiveLevel[37] = {};
    winobj.arrLiveLevel[37].credit = 34868000000;
    winobj.arrLiveLevel[38] = {};
    winobj.arrLiveLevel[38].credit = 41868000000;
    winobj.arrLiveLevel[39] = {};
    winobj.arrLiveLevel[39].credit = 49868000000;

    winobj.arrLiveLevel[40] = {};
    winobj.arrLiveLevel[40].credit = 58868000000;
    winobj.arrLiveLevel[41] = {};
    winobj.arrLiveLevel[41].credit = 68868000000;
    winobj.arrLiveLevel[42] = {};
    winobj.arrLiveLevel[42].credit = 79868000000;


    winobj.arrWealthLevel = new Array();
    winobj.arrWealthLevel[0] = {};
    winobj.arrWealthLevel[0].credit = 0;
    winobj.arrWealthLevel[1] = {};
    winobj.arrWealthLevel[1].credit = 2000;
    winobj.arrWealthLevel[2] = {};
    winobj.arrWealthLevel[2].credit = 12000;
    winobj.arrWealthLevel[3] = {};
    winobj.arrWealthLevel[3].credit = 42000;
    winobj.arrWealthLevel[4] = {};
    winobj.arrWealthLevel[4].credit = 142000;
    winobj.arrWealthLevel[5] = {};
    winobj.arrWealthLevel[5].credit = 342000;
    winobj.arrWealthLevel[6] = {};
    winobj.arrWealthLevel[6].credit = 742000;
    winobj.arrWealthLevel[7] = {};
    winobj.arrWealthLevel[7].credit = 1542000;
    winobj.arrWealthLevel[8] = {};
    winobj.arrWealthLevel[8].credit = 3142000;
    winobj.arrWealthLevel[9] = {};
    winobj.arrWealthLevel[9].credit = 5742000;
    winobj.arrWealthLevel[10] = {};
    winobj.arrWealthLevel[10].credit = 9742000;

    winobj.arrWealthLevel[11] = {};
    winobj.arrWealthLevel[11].credit = 16242000;
    winobj.arrWealthLevel[12] = {};
    winobj.arrWealthLevel[12].credit = 26242000;
    winobj.arrWealthLevel[13] = {};
    winobj.arrWealthLevel[13].credit = 41242000;
    winobj.arrWealthLevel[14] = {};
    winobj.arrWealthLevel[14].credit = 61242000;
    winobj.arrWealthLevel[15] = {};
    winobj.arrWealthLevel[15].credit = 86242000;
    winobj.arrWealthLevel[16] = {};
    winobj.arrWealthLevel[16].credit = 121242000;
    winobj.arrWealthLevel[17] = {};
    winobj.arrWealthLevel[17].credit = 171242000;
    winobj.arrWealthLevel[18] = {};
    winobj.arrWealthLevel[18].credit = 251242000;
    winobj.arrWealthLevel[19] = {};
    winobj.arrWealthLevel[19].credit = 271242000;
    winobj.arrWealthLevel[20] = {};
    winobj.arrWealthLevel[20].credit = 441242000;
    winobj.arrWealthLevel[21] = {};
    winobj.arrWealthLevel[21].credit = 671242000;

    winobj.arrWealthLevel[22] = {};
    winobj.arrWealthLevel[22].credit = 971242000;
    winobj.arrWealthLevel[23] = {};
    winobj.arrWealthLevel[23].credit = 1351242000;
    winobj.arrWealthLevel[24] = {};
    winobj.arrWealthLevel[24].credit = 1821242000;
    winobj.arrWealthLevel[25] = {};
    winobj.arrWealthLevel[25].credit = 2391242000;
    winobj.arrWealthLevel[26] = {};
    winobj.arrWealthLevel[26].credit = 3071242000;
    winobj.arrWealthLevel[27] = {};
    winobj.arrWealthLevel[27].credit = 4051242000;
    winobj.arrWealthLevel[28] = {};
    winobj.arrWealthLevel[28].credit = 5331242000;
    winobj.arrWealthLevel[29] = {};
    winobj.arrWealthLevel[29].credit = 7891242000;
    winobj.arrWealthLevel[30] = {};
    winobj.arrWealthLevel[30].credit = 10891242000;
    winobj.arrWealthLevel[31] = {};
    winobj.arrWealthLevel[31].credit = 15891242000;
    winobj.arrWealthLevel[32] = {};
    winobj.arrWealthLevel[32].credit = 23891242000;

    winobj.arrWealthLevel[33] = {};
    winobj.arrWealthLevel[33].credit = 31891242000;
    winobj.arrWealthLevel[34] = {};
    winobj.arrWealthLevel[34].credit = 39891242000;
    winobj.arrWealthLevel[35] = {};
    winobj.arrWealthLevel[35].credit = 47891242000;
    winobj.arrWealthLevel[36] = {};
    winobj.arrWealthLevel[36].credit = 55891242000;
    winobj.arrWealthLevel[37] = {};
    winobj.arrWealthLevel[37].credit = 63891242000;
    winobj.arrWealthLevel[38] = {};
    winobj.arrWealthLevel[38].credit = 71891242000;
    winobj.arrWealthLevel[39] = {};
    winobj.arrWealthLevel[39].credit = 79891242000;


    window.winobj = winobj;

})(window);