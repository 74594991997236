import axios from "axios"
import qs from "qs"

// 创建axios实例
const service = axios.create({
    baseURL:process.env.HTTP_URL, 
    timeout: 1000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }
});

// 请求拦截器
service.interceptors.request.use(
    config=>{	
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response=>{
        return response.data;
    }, 
    function (error) {
        return Promise.reject(error);
    }
);


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params={}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params:params})
        .then(response => {
            resolve(response);
        }).catch(err => {
            console.log(err);
        })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, qs.stringify(data))
        .then(response => {
			resolve(response);
		}, err => {
			reject(err)
		})
	})
}

export default {
	get,
	post
};



