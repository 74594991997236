import "./common/common.js"
import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import request from "@/utils/request.js"
import "amfe-flexible"
import Vant from "vant";
import "vant/lib/index.css";
import Vconsole from 'vconsole';
Vue.use(Vant);

if(process.env.NODE_ENV=='development'){
  new Vconsole();
}


import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie;
import store from './store'

Vue.prototype.request = request;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
