import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const state = {
  user_info:JSON.parse(localStorage.getItem("userInfo")) || {},
  token:localStorage.getItem("token")||'',
  individuation:false, // 控制个性化
};
const mutations = {
  userInfo (state,userInfo) {
    state.user_info = JSON.parse(userInfo)
  },
  token (state,token) {
    state.token = token;
  },
};
const action = {
  userInfo ({commit}, userInfo) {
    commit('USER_INFO',userInfo);
  },
};
const getters = {
  userInfo: state => state.user_info,
}
const store =  new Vuex.Store({
  state:state,
  mutations:mutations,
  action:action,
  getters:getters,
})
export default store


